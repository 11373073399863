import React, { useEffect, useState } from 'react';
import './ScrollEvent.css';

import biathlon from './Assets/lucas-sadesky-biathlon.jpg';
import pathfinding from './Assets/short.gif';
import bike from './Assets/bike.jpg';


function ScrollComponent({ title }) {
  const [scrollY, setScrollY] = useState(0);

  var scaledScroll = (scrollY/window.innerHeight);
  var startOffset = -2*window.innerWidth;


  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };    
  }, []);

  const cardStyle = {
    transform: `translateX(${Math.min(scrollY > window.innerHeight ? startOffset - window.innerWidth + scaledScroll*window.innerWidth : startOffset, 0)}px)`
   };


  return (
    <div className="scroll-container">
      <div className="card-container" style={cardStyle}>
        <div className="card">
          <img className='bg' src={bike} alt='ski tour'></img>
          <div className='text1'>
              <h2>Competitive Sport</h2>
              <p>As long as I can remember, I have been a cross-country skier. Between 2014 and 2021, I trained competitively on the Sovereign Lake
                  Nordic Ski Team for both Biathlon and Cross-country Skiing. Through my years in the program I developped many lifelong friendships and earned positions on a number of provincial
                  and national teams including the BC Ski Team and the Canadian Youth Olympic Team.
              </p>
              <a href="/about">
                  <button id="skiracingbutton">Learn More</button>
              </a>
          </div>
        </div>
        <div className="card">
          <img className='bg' src={pathfinding} alt='pathfinding'></img>
          <div className='text2'>
              <h2>{title} Portfolio</h2>
              <p>Check out my showcase of hands-on experiences, where I blend engineering 
               with a knack for software automation. From crafting eBikes to developing smart systems, each project 
                is a testament to my commitment to merging technology with practicality. Explore and get inspired!
              </p>
              <a href="/projects">
                  <button id="nextbtn">Learn More</button>
              </a>
          </div> 
        </div>
        <div className="card">
          <img className='bg' src={biathlon} alt='biking'></img>
          <div className='text3'>
              <h2>About Me</h2>
              <p>Hey, I'm Lucas Sadesky—an Honours Mechatronics Engineering student passionate about translating innovative ideas 
                into tangible, efficient solutions. 
              </p>
              <a href="/about">
                  <button id="nextabtn">Learn More</button>
              </a>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default ScrollComponent;
