// import React, {useEffect, useState} from 'react';
import React from 'react';
import './nav.css';

function Nav(){
    
    return(
        <>
            <nav>
                <ul>
                    <li id="logo_img">
                        <div id="dots">
                        </div>
                        <a href='/' id="navlink" >lucas sadesky</a>
                    </li>
                    <li><a id="navlink" className='underlined' href='/projects'>projects</a></li>
                    {/* <li><a id="navlink" href='/contact'>contact</a></li> */}
                    <li><a id="navlink" href='/about'>about</a></li>
                </ul>
            </nav>
        </>
    )
}

export default Nav;