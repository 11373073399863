import './About.css'
// import React, {useEffect, useState} from 'react';
import React from 'react';
import Nav from './nav';

import mtn from './Assets/mtn.jpeg';
import chickaboom from './Assets/chickaboom.jpeg';

import linkedin from './Assets/linkedin.png';
import instagram from './Assets/instagram.webp';
import github from './Assets/github.png';



function About() {
    return (
        <>
        <Nav/>
        <div id="main">
        <img className='mtn' src={mtn} alt='mtn'></img>
        </div>
        <div className="about_title">
            <h2>about</h2>
            <div className='about_p'>
                <h4>
                    Hi, I'm Lucas Sadesky.
                </h4>
                <div className='top'>
                    <p className='top'>
                    I am a Mechatronics Engineering student at the University of Waterloo. 
                    I am passionate about the outdoors, and I love to ski, mountain bike, climb, and anything else outside! 
                    I am bilingual and passionate about engineering and design. I am always looking for new projects and challenges to take on. 
                    {/* I am currently looking for a co-op position for the Summer 2023 term. */}
                    </p>
                </div>


                <p className='mid'>                
                I am from Vernon, BC and grew up in the mountains which has given me the foundation for my love of the outdoors.
                I have been ski racing though all of high school and have had the opportunity to race on various teams including, the BC Ski Team, 
                the National Biathlon Development Team, and the Canadian Youth Olympic Team.
                I have competed nationally for both Skiing and Biathlon and internationally for 
                Biathlon at the Lausanne 2020 Youth Olympic Games.
                I now race for the University of Waterloo Nordic Ski Team.
                I believe that being a well rounded person is the foundation for everything else in life. Not only is it the 
                key to professional and academic success but by having an array of interests and skills allows for cross-disciplinary
                thinking and problem solving. 
                </p>

                <p>
                    In addition to my engineering and outdoor pursuits, I partake in a number of other activities. 
                    I have been playing the piano and producing music since my early teenage years.
                    I love to take photos, like the one behind this text and below. I love to garden
                    and grow my own food. I love to learn about new things and I am always looking for new experiences and activities.

                </p>
            </div>
        </div>
        <img className='mtn' src={chickaboom} alt='mtn'></img>
        <div className="contact_title">
            <h2>contact</h2>
            <p>
            If you would like to get in touch, I'd love to chat. Send me an email or connect with me on <a href='https://www.linkedin.com/in/lucas-sadesky/'>LinkedIn</a>.
            </p>
            <a href="mailto:lsadesky@uwaterloo.ca">lsadesky@uwaterloo.ca</a>
            <p className='resume'>Resume available upon request.</p>
        </div>   
        <footer>
                <div className='footer'>
                    <h3>Links</h3>
                    <ul className='footer-list'>
                        <li><a href="https://www.linkedin.com/in/lucas-sadesky/"><img className='icon' src={linkedin} alt='linkedin'></img></a></li>
                        <li><a href="https://www.instagram.com/lucasadesky/?hl=en"><img className='icon' src={instagram} alt='instagram'></img></a></li>
                        <li><a href="https://github.com/lucasadesky"><img className='icon' src={github} alt='github'></img></a></li>
                        <li><a href='#top'>return to top ^</a></li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default About;