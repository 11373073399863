import './Projects.css';
// import React, {useEffect, useState} from 'react';
import React from 'react';
import Nav from './nav';

import p5js from './Assets/p5js.jpg';
import plants from './Assets/plants.jpeg';
import ebike from './Assets/ebike.jpeg';
import motor from './Assets/motor.jpeg';
import bikecomputer from './Assets/bikecomputer.jpeg'
import kerosene from './Assets/kerosene.jpeg'
import plant2 from './Assets/plant2.jpeg'
import bmp from './Assets/bmp.png'
import bmpsad from './Assets/bmp_sad.png'

import linkedin from './Assets/linkedin.png';
import instagram from './Assets/instagram.webp';
import github from './Assets/github.png';

function Projects() {

    return (
        <>
        <div className="Projects">
                <Nav/>
                <img className='flwr' src={p5js} alt='flwr'></img>
                <div className="title">
                    <h2>projects</h2>
                    <h5>"Single Line", Image created in p5js using javascript.</h5>
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <img src={bikecomputer} alt='plants'></img>
                    </div>
                    <div className='projbox'>
                        <h3>Bike Computer</h3>
                        <ul>
                            <li>Based on a Raspberry Pi Pico W, GPS module, modified SD card and eInk Display</li>
                            <li>eInk display for visibility in direct sunlight</li>
                            <li>Designed and 3D printed an enclosure and mount to attach the device to my road bike</li>
                            <li>Programmed in C, C++, MicroPython with a web interface to upload activities to <em><a href='https://www.strava.com/activities/11498402716'>Strava</a></em></li>
                            <li>Parsed GPS data stream for desired data and created routine to periodically upload data to the SD card avoiding GPS read cycles, saving power and preventing write errors</li>
                        </ul>
                    </div>
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <h3>Retrofit of old Kerosene Lamp</h3>
                        <ul>
                            <li>Used scraps from partial LED strips that were to be thrown away</li>
                            <li>Potentiometers for Red, Green, Blue control and power switch</li>
                            <li>3D printed enclosure.</li>
                        </ul>
                    </div>
                    <div className='projbox'>
                        <img src={kerosene} alt='plants'></img>
                    </div>
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <img src={plant2} alt='plants'></img>
                        <img src={bmp} alt='bmp'></img>
                        <img src={bmpsad} alt='bmpsad'></img>
                    </div>
                    <div className='projbox'>
                        <h3>Moisture Sensing Smart Plant Pot</h3>
                        <ul>
                            <li>Created custom Bitmap images to mirror the image of the plant</li>
                            <li>Used a Raspberry Pi Pico W with Arduino Core (written in C++).</li>
                            <li>Screen can be turned on and off from local address.</li>
                            <li>Moisture levels can be monitored via online dashboard.</li>
                            <li>OLED display to indicate the plant’s “happiness level.</li>
                        </ul>
                    </div>
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <h3>Automated Indoor Grow System</h3>
                        <ul>
                            <li>Automated indoor grow system for growing microgreens</li>
                            <li>Arduino based control system</li>
                            <li>Automated watering and lighting</li>
                            <li>Test various moisture profiles</li>
                        </ul>
                    </div>
                    <div className='projbox'>
                        <img src={plants} alt='plants'></img>
                    </div>
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <img src={ebike} alt='ebike'></img>
                        <img src={motor} alt='motor'></img>
                    </div>
                    <div className='projbox'>
                        <h3>Personal eBike</h3>
                        <ul>
                            <li>Built and designed many iterations. Diagnosed electrical equipment failures.</li>
                            <li>Calculated power and torque requirements for the hills nearby.</li>
                            <li>Designed and soldered battery pack for power requirements.</li>
                            <li>Rewired the motor with lower gauge wire for higher current.</li>
                        </ul>
                    </div>
                    
                </div>
                <div className='projcard'>
                    <div className='projbox'>
                        <h3>This page is a work in progress (and also a project of mine)</h3>
                        <ul>
                            <li>Written in React.</li>
                            <li>I will be adding new things here from time to time.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <footer className='proj-footer'>
                <div className='footer-plz'>
                    <h3>Links</h3>
                    <ul className='footer-list'>
                        <li><a href="https://www.linkedin.com/in/lucas-sadesky/"><img className='icon' src={linkedin} alt='linkedin'></img></a></li>
                        <li><a href="https://www.instagram.com/lucasadesky/?hl=en"><img className='icon' src={instagram} alt='instagram'></img></a></li>
                        <li><a href="https://github.com/lucasadesky"><img className='icon' src={github} alt='github'></img></a></li>
                        <li><a href='#top'>return to top ^</a></li>
                    </ul>
                </div>
            </footer>
            </>
        
      );
}


export default Projects;
