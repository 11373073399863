import './Home.css';
// import React, {useEffect, useState} from 'react';
import React from 'react';
import ScrollComponent from './ScrollEvent';
import Nav from './nav.js';

import hornbyJump from './Assets/hornbyjumpscaled.jpeg';
import linkedin from './Assets/linkedin.png';
import instagram from './Assets/instagram.webp';
import github from './Assets/github.png';

function Home(){

  return (
    <div className="App">
        <div id="main">
            <div id="bigbackground">
                <Nav/>
                {/* <top></top> */}
                <img className='mainBg' src={hornbyJump} alt='skitour'></img>

                <div id="main_message">
                    <h2>Hey, I'm <em>Lucas Sadesky</em> </h2>
                    <p> Engineer, Athlete and everything in between. </p>
                </div>
                <div className="cards">
                    <ScrollComponent title="Projects"/>
                </div>
                
            </div>
            <footer className='home-footer'>
                <div className='footer-plz'>
                    <h3>Links</h3>
                    <ul className='footer-list'>
                        <li><a href="https://www.linkedin.com/in/lucas-sadesky/"><img className='icon' src={linkedin} alt='linkedin'></img></a></li>
                        <li><a href="https://www.instagram.com/lucasadesky/?hl=en"><img className='icon' src={instagram} alt='instagram'></img></a></li>
                        <li><a href="https://github.com/lucasadesky"><img className='icon' src={github} alt='github'></img></a></li>
                        <li><a href='#top'>return to top ^</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    </div>
  );
}

export default Home;